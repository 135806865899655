import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, IconButton, CardContent, Backdrop, Box, Stack } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { getVideoaskEditToken } from 'api/videoask';

// style constant
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: '94%',
            maxHeight: '100%',
            overflow: 'scroll'
        }
    }
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    bgcolor: 'white',
    borderRadius: 3,
    boxShadow: 30,
    p: 2
};

const VideoAskPopup = (props) => {
    const classes = useStyles();
    
    const { title, videoToken, closePopup, email } = props;
    const [videoAskSrc, setVideoAskSrc] = useState('');

    useEffect(() => {
        const buildSrc = async () => {
            const videoAskToken = await getVideoaskEditToken();
            let url = `https://welcome.digitalvillage.app/${videoToken}`;

            if (email)
                url += `#contact_email=${email}`;
            if (videoAskToken)
                url += `${email ? '&' : '#'}update_token=${videoAskToken}`;

            setVideoAskSrc(url);
        };
        buildSrc();
    }, [videoToken, email]);

    return (
        <Fragment>
            <Modal
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                open={videoToken != null}
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                <Box sx={{ ...style }}>
                    <CardContent>
                        <Stack direction={'row'} alignContent={'center'} display={'flex'} justifyContent={'space-between'}>
                            <Typography id="modal-modal-title" variant="h2" component="h2" mb={2}>
                                {title}
                            </Typography>
                            <IconButton>
                                <CloseIcon color="black" onClick={closePopup}></CloseIcon>
                            </IconButton>
                        </Stack>
                        <iframe
                            title={title}
                            src={videoAskSrc}
                            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                            width="100%"
                            height="600px"
                            style={{ borderRadius: '10px', border: 'none' }}
                        ></iframe>
                    </CardContent>
                </Box>
            </Modal>
        </Fragment>
    );
};

VideoAskPopup.propTypes = {
    title: PropTypes.string,
    videoToken: PropTypes.string,
    closePopup: PropTypes.func,
    email: PropTypes.string
};

export default VideoAskPopup;
