import api from 'api';

export const getVideoaskEditToken = async () => {
    try {
        const response = await api.get('/user/videoask/updateToken');
        return response?.data?.update_token || '';
    } catch (error) {
        console.warn('Error fetching videoask edit token:', error);
        return '';
    }
};